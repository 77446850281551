<template>
  <div class="page_content_wrap fixedIncomePage">
    <div class="income_wrap py-5">
      <div class="container">
        <div class="income_box" v-if="!isLoading">
          <h2 class="text-dark mb-2">
            Now, please estimate the fixed income you will have during
            retirement.
          </h2>
          <p class="text-dark font-size-lg mb-5">
            Select age and use the sliders to estimate your income
          </p>
          <form action="" @submit.prevent="submitWizard">
            <div
              class="form-group range_slider_wrap mb-5"
              :key="index"
              v-for="(income, index) in incomes"
            >
              <div class="edit-and-print">
                <div class="row">
                  <div class="col-3 col-sm-3">
                    <div class="form-group select2_lg m-0">
                      <div class="row align-items-center">
                        <div class="col-12 col-sm-4">
                          <label class="text-dark">Age</label>
                        </div>
                        <div class="col-12 col-sm-8">
                          <Select2
                            v-model.number="income.startingAge"
                            placeholder="Select Age"
                            :options="ageOptions"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-9 col-sm-9">
                    <div class="row align-items-center">
                      <div class="col-12 col-sm-4 h6 text-dark">
                        <input
                          v-if="index == currentEdit"
                          type="text"
                          class="form-control"
                          v-model="income.title"
                        />
                        <span v-if="index !== currentEdit">{{
                          income.title
                        }}</span>
                      </div>
                      <div class="col-7 col-sm-5">
                        <div class="d-flex align-items-center">
                          <h4 class="m-0 text-primary mr-1">$</h4>
                          <h4
                            class="m-0 text-primary d-flex align-items-center"
                          >
                            <input
                              type="number"
                              class="form-control mr-1"
                              placeholder="10000"
                              min="0"
                              max="200000"
                              v-model="income.value"
                            />
                            <small class="text-dark font-size-sm"
                              >/{{ income.timeFrame }}</small
                            >
                          </h4>
                        </div>
                      </div>
                      <div class="col-5 col-sm-3">
                        <div class="form_repeater_action">
                          <a
                            href="javscript:void(0);"
                            class="border border-gray-300"
                            @click.prevent="enableEdit(index)"
                            ><i class="fal fa-edit"></i
                          ></a>
                          <a
                            href="javscript:void(0);"
                            class="border border-gray-300"
                            @click.prevent="removeIncome({ index, income })"
                            ><i class="fal fa-trash-alt"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <r-slider
                :min="1"
                :max="200000"
                :step="10"
                v-model.number="income.value"
              ></r-slider>
            </div>

            <div class="form-group mb-5 form_another_item">
              <button
                type="button"
                class="btn btn-outline-info"
                @click="enableAddNewBtn"
              >
                <i
                  class="fas fa-plus border border-gray-300 mr-1"
                  v-if="!enableAddNew"
                ></i>
                <i
                  class="fas fa-minus border border-gray-300 mr-1"
                  v-if="enableAddNew"
                ></i>
                {{ enableAddNew ? "Cancel" : "Add Another Item" }}
              </button>
            </div>

            <div class="form-group mb-5 form_repeater_item" v-if="enableAddNew">
              <div class="row">
                <div class="col-sm-12 col-md-8 mb-3 mb-md-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type of Income"
                    v-model="newIncomeTitle"
                  />
                </div>
                <div class="col-sm-12 col-md-4">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-block"
                    @click="addNewIncome"
                    :disabled="newIncomeTitle === ''"
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>

            <div class="btn_continue_wrap">
              <button
                type="submit"
                class="btn btn-primary btn-lg btn-block mt-4"
                :disabled="isSubmitting"
                :class="{ 'btn-load': isSubmitting }"
              >
                CONTINUE <i class="fas fa-arrow-right pl-1"></i>
              </button>
            </div>
          </form>
        </div>
        <div class="loader" v-if="isLoading"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";
import { HUBSPOT_FIXED_INCOME_LIST_KEY } from "@/utils/constants";
import { parse } from "query-string";
import PERSONAL_DETAIL_QUESTIONS from "@/data/personal-details-questions";
export default {
  name: "Screen10",
  data() {
    return {
      currentEdit: null,
      isLoading: true,
      isSubmitting: false,
      errors: [],
      enableAddNew: false,
      newIncomeTitle: "",
      hubspotQuestionId: HUBSPOT_FIXED_INCOME_LIST_KEY,
      ageOptions: PERSONAL_DETAIL_QUESTIONS.retirement_age.options,
    };
  },
  computed: {
    ...mapGetters({
      getUser: "getUser",
      getUserId: "getUserId"
    }),
    ...mapGetters("wizard", {
      incomes: "getFixedIncomes",
      survey: "getSurvey",
    }),
  },
  mounted() {
    this.isLoading = true;

    // getContact([this.hubspotQuestionId, "marital_status", "are_you_retired"])
    const contactId = "kyle@dataskeptic.com"
    getContact(contactId)
      .then((response) => {
        let prop = response.data.properties;

        console.log("prop");
        console.log(prop);
        console.log(this.hubspotQuestionId);

        let defaults = [
          {
            title: "Social Security",
            timeFrame: "annual",
            value: 0,
            startingAge: 65,
            inflationRate: 0.017,
          },
          {
            title: "Pension Income",
            timeFrame: "annual",
            value: 0,
            startingAge: 65,
          },
          {
            title: "Annuity Income",
            timeFrame: "annual",
            value: 0,
            startingAge: 65,
          },
          {
            title: "Other Income",
            timeFrame: "annual",
            value: 0,
            startingAge: 65,
          },
          ...(prop.marital_status === "Married"
            ? [
                {
                  title: "Spouse's Social Security",
                  timeFrame: "annual",
                  value: 0,
                  startingAge: 65,
                  inflationRate: 0.017,
                },
              ]
            : []),
        ].filter(
          (obj) => obj.title.toLowerCase() !== "spouse's social security"
        );

        let fixed_income_list = prop[this.hubspotQuestionId]
          ? JSON.parse(prop[this.hubspotQuestionId])
          : [];

        const parsedData = fixed_income_list.length
          ? fixed_income_list
          : defaults;

        console.log("response.data.properties");
        console.log(parsedData);
        this.SET_FIXED_INCOMES(parsedData);

        this.isLoading = false;
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapMutations("wizard", [
      "SET_FIXED_INCOMES",
      "ADD_NEW_FIXED_INCOME",
      "REMOVE_FIXED_INCOME",
    ]),
    enableAddNewBtn() {
      this.newIncomeTitle = "";
      this.enableAddNew = !this.enableAddNew;
    },
    enableEdit(index) {
      this.currentEdit = index;
    },
    removeIncome(obj) {
      this.REMOVE_FIXED_INCOME(obj);
    },
    addNewIncome() {
      console.log(this.newIncomeTitle);
      if (this.newIncomeTitle != "") {
        this.ADD_NEW_FIXED_INCOME({
          title: this.newIncomeTitle.replace(/\$+/g, ""),
          timeFrame: "annual",
          value: 0,
          startingAge: null,
        });

        this.newIncomeTitle = "";
        this.enableAddNew = false;
      }
    },
    submitWizard(e) {
      this.isSubmitting = true;
      this.currentEdit = null;
      const uu = this.getUser || {}
      const contactId = uu.email
      console.log('this')
      console.log(this)
      const hubspotQuestionId = HUBSPOT_FIXED_INCOME_LIST_KEY
      updateContact(contactId, { hubspotQuestionId: JSON.stringify(this.incomes) })
        .then((response) => {
          const queryParams = parse(location.search);
          if (queryParams["from-review"]) {
            this.$router.push({ name: "risk.review" });
          } else {
            let userObj = {
              riskLink: "risk.monthlyExpence",
            };
            this.updateProgress({ step: 1, data: userObj });
            this.$router.push({ name: "risk.monthlyExpence" });
          }
          // this.updateProgress();
        })
        .catch((error) => {
          console.log(error);
          this.isSubmitting = false;
          alert("Something went wrong!");
        });

      e.preventDefault();
    },
  },
};
</script>
<style >
.fixedIncomePage .range_slider_wrap .select2-container {
	width:81px !important;
}

.fixedIncomePage  .select2-container--default .select2-selection--single {
  height: 50px;
	width:81px;
}

.fixedIncomePage .select2-container .select2-selection--single {
  height: 50px !important;
	width:81px;
}

.fixedIncomePage .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 50px !important;
}

.fixedIncomePage .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px !important;
}
</style>